// const API_URL = process.env.VUE_APP_API_URL

const POST = async function (object = {}) {
  // eslint-disable-next-line
  const { path, ...data } = object
  const request = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  const url = 'https://hooks.zapier.com/hooks/catch/8436949/bkee8pq/'
  const response = await fetch(url, {
    ...request,
  })
  return response
}

const GET = async function (data) {
  const url = 'https://hooks.zapier.com/hooks/catch/8436949/bkee8pq'
  const query = Object.keys(data)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
    .join('&')
  const response = await fetch(`${url}?${query}`)
  return response.json()
}

export { POST, GET }
