<template>
  <div class="site__head">
    <router-link to="/" class="site__head-logo">
      <img src="@/assets/logo-leanovatica.png" alt="Leanovatica" />
    </router-link>
  </div>
  <router-view />
</template>

<script>
export default {
  name: 'Site',
}
</script>

<style lang="scss">
.site {
  &__head {
    flex: 1 0 100%;
    width: 100%;
    height: 7rem;
    flex: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      padding-top: 1rem;
    }

    &-logo {
      margin: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      img {
        max-width: 300px;

        @media screen and (max-width: 768px) {
          max-width: 80%;
          margin: 0 auto;
          max-height: 60px;
        }
      }
    }
  }
}
</style>
