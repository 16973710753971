import { createStore } from 'vuex'
import application from './application'
import form from './form'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    application,
    form,
  },
})
