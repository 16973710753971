<template>
  <div class="thankyou__container">
    <p>
      <strong> Dziękujemy za kontakt! </strong>
    </p>
    <p>Odezwiemy się do Ciebie maksymalnie w ciągu dwóch dni roboczych.</p>
    <p>
      <strong>Do usłyszenia,</strong>
      Zespół Leanovatica
    </p>
  </div>
</template>

<script>
export default {
  name: 'Thankyou',

  mounted() {
    // this.$router.push('/')
  },
}
</script>

<style lang="scss">
.thankyou {
  &__container {
    border: 0;
    padding: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: var(--color);
    color: var(--color-bg);
    border-radius: var(--border-radius-xl);
    max-width: 60%;
    align-items: flex-start;
    margin: 0 auto;

    @media screen and (max-width: 1080px) {
      margin: 1rem 0rem;
      padding: 2rem;
      max-width: 100%;
    }

    p {
      margin: 0.75rem 0;
      line-height: 1.75;
      font-size: 1.125rem;

      &:first-child {
        font-size: 1.5rem;
        margin: 0.75rem 0 0rem;
        color: var(--color-action);
        line-height: 1.5;
        padding-right: 2rem;

        @media screen and (max-width: 1080px) {
          padding: 0;
          line-height: 1.25;
          font-size: 1.75rem;
          margin-bottom: 1.25rem;
        }
      }

      &:last-child {
        font-weight: 500;
        margin-top: 1rem;

        strong {
          font-size: 1.125rem;
          display: block;
        }
      }
    }
  }

  &__recommendations {
    flex: 1;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1080px) {
      padding: 1rem 0 0;
    }

    h4 {
      margin: 0 0 2rem;
    }

    &-item {
      text-decoration: none;

      & + & {
        margin-top: 1rem;
      }

      span {
        display: block;
        text-transform: uppercase;
        color: var(--color-action);
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
      }

      img {
        display: block;
        max-width: 100%;
        filter: drop-shadow(3px 4px 10px rgba(0, 0, 0, 0.5));
      }
    }
  }
}
</style>
