import { GET } from '@/helpers/http'

export const types = {
  FORM_FILLED_COMMIT: 'formFilledCommit',
  SEND_FORM_COMMIT: 'sendFormCommit',
}

export const actionNames = {
  FORM_FILLED: 'formFilled',
  SEND_FORM: 'sendForm',
}

export default {
  namespaced: true,
  state: {
    formFilled: false,
    formSended: false,
  },

  mutations: {
    [types.FORM_FILLED_COMMIT](state, status) {
      state.formFilled = status
    },
    [types.SEND_FORM_COMMIT](state, status) {
      state.formSended = status
    },
  },

  actions: {
    [actionNames.FORM_FILLED]({ commit }, status) {
      commit(types.FORM_FILLED_COMMIT, status)
    },

    async [actionNames.SEND_FORM]({ commit }, data) {
      const result = await GET(data)
      const status = result.status
      if (status === 'success') {
        commit(types.SEND_FORM_COMMIT, true)
        return true
      } else {
        commit(types.FORM_FILLED_COMMIT, 'error')
        return false
      }
    },
  },
}
