export default {
  namespaced: true,
  state: {
    data: {
      email: null,
      telephone: null,
      regulations: false,
      marketing: false,
    },
    fields: {
      email: {
        label: 'E-mail służbowy',
        type: 'email',
      },
      telephone: {
        label: 'Telefon',
        type: 'tel',
      },
    },

    gdpr: {
      regulations:
        'Zapoznałem się i zgadzam się z zapisami <a href="https://leanovatica.com/privacy-pl.pdf" target="_blank">Polityki prywatności</a>, a swoje dane do kontaktu zostawiam świadomie i dobrowolnie. Mam świadomość, że mogę zażądać poprawiania swoich danych osobowych, ograniczenia ich przetwarzania lub usunięcia.<sup>*</sup>',
      marketing:
        'Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu przez Leanpassion Sp. z o.o. ul. Komitetu Obrony Robotników 48, 02-146 Warszawa, NIP: 5213595882 (Administratora) celem przedstawienia informacji marketingowych, w tym udzielenia informacji będących odpowiedzią na zgłoszenie.',
    },
  },
}
