import { createRouter, createWebHistory } from 'vue-router'
import Site from '../views/Site.vue'
import Home from '../views/Home'
import Thankyou from '../views/Thankyou'

const routes = [
  {
    path: '/',
    name: 'Site',
    component: Site,
    children: [
      {
        path: '/',
        component: Home,
      },
    ],
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: Thankyou,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
