<template>
  <div id="site-main" class="site">
    <div class="site__wrapper">
      <div class="site__container" :class="{ 'is--sended': isThankyou }">
        <router-view />
      </div>
      <div v-if="!isThankyou" class="site__form">
        <Form />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from '@/views/Form'

export default {
  name: 'Leanpassion',

  data() {
    return {}
  },

  computed: {
    ...mapState({
      formSended: (state) => state.application.formSended,
      formFilled: (state) => state.application.formFilled,
      isThankyou() {
        return this.$route.path.includes('thankyou') ? true : false
      },
    }),
  },

  methods: {},

  components: {
    Form,
  },
}
</script>

<style lang="scss">
@import url('style/_theme.scss');
@import url('style/main.scss');

html {
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.site {
  height: 100vh;
  overflow-y: auto;
  position: relative;
  z-index: 9;

  &.section {
    .site__wrapper {
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 1.5rem;
    flex-direction: column;
  }

  &__navigation {
    position: absolute;
    z-index: 19;
    max-width: 1600px;
    width: 100%;
    top: 0;
    left: 50%;
    display: flex;
    justify-content: flex-start;
    padding: 1.5rem 3rem 0;
    padding-right: 35%;
    transition: var(--transition);
    opacity: 0;
    transform: translate3d(-50%, -150%, 0);

    @media screen and (max-width: 1080px) {
      max-width: 100%;
      padding: 1rem 1rem;
    }

    &.is--active {
      opacity: 1;
      transform: translate3d(-50%, 0%, 0);
    }

    &::before {
      content: '';
      position: absolute;
      top: -2rem;
      left: 0rem;
      height: 300%;
      width: 40%;
      background: #000;
      opacity: 0.8;
      z-index: 1;
      filter: blur(50px);

      @media screen and (max-width: 1080px) {
        width: 100%;
        height: 100px;
        opacity: 0.97;
        background: #171717;
        top: -1.25rem;
        filter: none;
        box-shadow: 0 10px 15px 20px #171717;
      }

      @media screen and (max-width: 768px) {
        height: 70px;
        box-shadow: 0 30px 10px #171717;
      }
    }

    &-item {
      flex: 0;
      position: relative;
      z-index: 2;
      opacity: 0.4;
      filter: grayscale(50%);
      transition: var(--transition);

      @media screen and (max-width: 1080px) {
        flex: 1;
        max-width: 33%;
        padding: 0.5rem 0 1rem;
      }

      &.router-link-exact-active,
      &:hover {
        opacity: 1;
        filter: grayscale(0%);
      }

      & + & {
        margin-left: 2rem;

        @media screen and (max-width: 1080px) {
          margin-left: 1rem;
        }
      }

      img {
        display: block;
        height: 35px;

        @media screen and (max-width: 1080px) {
          height: 40px;
          margin: 0 auto;
        }
        @media screen and (max-width: 768px) {
          height: 22px;
        }
      }
    }
  }

  &__wrapper {
    padding: 3rem;
    display: flex;
    align-items: center;
    text-align: left;
    max-width: 1600px;
    min-height: 100%;
    margin: auto;
    position: relative;

    @media screen and (max-width: 1080px) {
      padding: 0;
      flex-direction: column;
    }
  }

  &__form {
    flex: 1 auto;
    padding-left: 2rem;
    display: flex;
    top: 3rem;
    position: sticky;
    z-index: 19;
    transition: var(--transition);

    @media screen and (max-width: 1080px) {
      padding: 0;
      margin: 0 auto;
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 55%;
    min-width: 55%;
    position: relative;
    z-index: 19;

    &.is--sended {
      max-width: 90%;
      min-width: 90%;
      margin: 0 auto;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (max-width: 1080px) {
        max-width: unset;
        min-width: unset;
        margin: auto;
      }
    }

    @media screen and (max-width: 1080px) {
      max-width: unset;
      min-width: unset;
      width: 100%;
    }
  }
}
</style>
