<template>
  <div class="site__home">
    <h1>
      <span>Chcesz lepiej angażować</span>
      <strong>pracowników w rozwój?</strong>
    </h1>
    <h3>Leanovatica to:<br /></h3>
    <ul class="site__home-list">
      <li>wsparcie dla HR</li>
      <li>narzędzie wspierające organizacje uczące się</li>
      <li>sposób na zwiększenie zaangażowania pracowników</li>
    </ul>
    <div class="site__home-welcome">
      <h2>
        Zapisz się na bezpłatną konsultację<br />
        i zoptymalizuj procesy rozwojowe w firmie
      </h2>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',

  computed: {
    ...mapState({
      programs: (state) => state.application.programs,
    }),
  },
}
</script>

<style lang="scss">
.site {
  &__home {
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0 0 1rem;
      padding: 1rem 0 3rem;
      text-align: left;
      font-size: 3rem;
      line-height: 1.1;
      font-weight: 600;
      position: relative;
      color: var(--color-action);
      display: flex;
      flex-direction: column;

      strong {
        font-weight: 800;
        font-size: 3.5rem;

        @media screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 7rem;
        height: 5px;
        background: var(--color-action);
      }
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 600;
      margin: 1rem 0 0;
      line-height: 1.5;

      @media screen and (max-width: 768px) {
        font-size: 1.25rem;
      }
    }

    &-list {
      font-size: 1.275rem;
      line-height: 1.2;
      padding: 0;
      margin: 0.5rem 0 1rem;
      padding: 0 0 0 2rem;

      @media screen and (max-width: 768px) {
        font-size: 1.125rem;
      }

      li {
        padding-bottom: 0.5rem;
      }
    }

    &-welcome {
      padding: 1rem 0 1rem 0;
      padding-right: 1.5rem;
      font-weight: 400;
      line-height: 1.7;

      @media screen and (max-width: 768px) {
        padding-right: 0;
      }

      h2 {
        font-size: 1.5rem;
        line-height: 1.25;
        margin: 1rem 0;
        color: var(--color-action);
        text-transform: uppercase;
        font-weight: 800;
        text-align: left;

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
          line-height: 1.25;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0.5rem 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          flex: 1 100%;
          max-width: 100%;
          position: relative;
          padding: 0 0 0.5rem;
          font-size: 1.5rem;
          line-height: 1.25;
          text-align: center;
          font-weight: 600;

          @media screen and (max-width: 768px) {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}
</style>
