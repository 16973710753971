<template>
  <div class="form__container">
    <form id="lp-form">
      <fieldset
        :class="{ 'is--validated': validation }"
        @keypress="enterPressed"
      >
        <div class="form__container-head">
          <span class="form__title">
            Wypełnij formularz, aby zapisać się na bezpłatną konsultację
          </span>
          <span class="form__errors-info" :class="{ 'is--active': errors }">
            Uzupełnij poprawnie pola oznaczone na czerwono
          </span>
          <span
            class="form__errors-info"
            :class="{ 'is--active': formFilled === 'error' }"
          >
            Niestety nie udało się wysłać zgłoszenia. Spróbuj później.
          </span>
        </div>
        <label
          v-for="(item, i) of fields"
          :key="`label-${i}`"
          class="form__label"
          :class="`type--${item.type}`"
        >
          <span>{{ item.label }}</span>
          <input
            v-if="item.type === 'tel'"
            :type="item.type"
            :id="`form-${i}`"
            :placeholder="item.type === 'tel' ? '500500500' : ''"
            v-model.number="form[i]"
            @keypress="isNumber"
            required
            class="form__input"
            maxLength="9"
            minLength="9"
            pattern="[0-9]{9}"
          />
          <input
            v-if="item.type !== 'tel'"
            :type="item.type"
            :id="`form-${i}`"
            v-model="form[i]"
            required
            class="form__input"
            :pattern="
              item.type === 'email'
                ? '[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}'
                : ''
            "
          />
        </label>
        <div class="form__gdpr">
          <label
            v-for="(item, i) of gdpr"
            :key="`checkbox-${i}`"
            class="form__checkbox"
          >
            <input
              type="checkbox"
              :value="`gdpr-${i}`"
              :required="i === 'regulations'"
              v-model="form[i]"
            />
            <p v-html="item" />
          </label>
        </div>
        <button
          type="button"
          class="form__cta"
          :class="{ 'is--filled': formFilled }"
          @click="checkForm"
        >
          <span>{{ formFilled ? 'Proszę czekać...' : 'Zapisz się' }}</span>
        </button>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { actionNames } from '@/store/application'

export default {
  name: 'Form',

  props: {
    showFormLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      validation: false,
      errors: false,
    }
  },

  computed: {
    ...mapState({
      form: (state) => state.form.data,
      fields: (state) => state.form.fields,
      gdpr: (state) => state.form.gdpr,
      formFilled: (state) => state.application.formFilled,
    }),
  },

  methods: {
    enterPressed(event) {
      if (event.keyCode === 13) {
        this.checkForm()
      }
    },

    checkForm() {
      if (this.formFilled) return false

      this.validation = true
      const form = document.getElementById('lp-form')
      const isValid = form.checkValidity()
      this.errors = !isValid ? true : false

      if (isValid) {
        this.errors = false
        this.sendForm()
      } else {
        this.errors = true
      }
    },

    isNumber(e) {
      if (!/\d/.test(e.key)) return e.preventDefault()
    },

    async sendForm() {
      this.$store.dispatch(`application/${actionNames.FORM_FILLED}`, true)

      const data = {
        ...this.form,
        user_tag: 'webinar_kusnierewicz',
      }
      const request = await this.$store.dispatch(
        `application/${actionNames.SEND_FORM}`,
        data
      )
      if (request) {
        this.$router.push({
          name: 'Thankyou',
        })
      }
    },
  },
}
</script>

<style lang="scss">
.form {
  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 1.5rem;
  }
  &__container {
    padding-left: 2rem;

    &-label {
      font-weight: 600;
      display: block;
      font-size: 1rem;
      margin: 0;
      padding: 0;
      text-align: center;
      max-height: 0px;
      overflow: hidden;
      opacity: 0;
      transition: var(--transition);

      @media screen and (max-width: 1080px) {
        font-size: 1.2rem;
      }

      br {
        display: none;

        @media screen and (max-width: 1080px) {
          display: block;
        }
      }

      &.is--active {
        opacity: 1;
        max-height: 3rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 1080px) {
          max-height: 4rem;
        }
      }
    }

    @media screen and (max-width: 1080px) {
      position: relative;
      padding: 1rem 0 0;
      max-width: 600px;
      margin: auto;
    }

    &-head {
      font-weight: 600;
      font-size: 1rem;
      width: 100%;

      span {
        display: block;
        @media screen and (max-width: 1080px) {
          line-height: 1.2;
        }

        &.form__errors-info {
          display: flex;
          align-items: center;
          font-size: 0.7rem;
          font-weight: 700;
          color: var(--color-error);
          height: 0rem;
          opacity: 0;
          transition: var(--transition);

          &.is--active {
            opacity: 1;
            height: auto;
            margin: -0.5rem 0 0.75rem;
          }
        }
      }
    }

    fieldset {
      border: 0;
      padding: 2rem 2.5rem;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: var(--color);
      color: var(--color-bg);
      border-radius: var(--border-radius-xl);

      @media screen and (max-width: 768px) {
        margin: 1rem 0 3rem;
        padding: 1.5rem;
      }

      &.is--validated {
        input:invalid {
          border-color: var(--border-color-error);
        }
      }

      input:invalid[type='checkbox'] + p::before {
        border-color: var(--border-color-error);
      }
    }
  }

  &__label {
    display: flex;
    flex: 1 0 100%;
    padding: 0 0 1rem;
    max-width: 100%;
    flex-direction: column;

    span {
      min-width: 7rem;
      font-size: 0.85rem;
      font-weight: 600;
      padding: 0 0 0.5rem;
    }

    &.type--text {
      max-width: 48%;
      @media screen and (max-width: 1080px) {
        max-width: 100%;
      }
    }
  }

  &__input,
  &__textarea {
    border: 1px solid var(--border-color);
    background-color: var(--color);
    font-size: 16px;
    border-radius: var(--border-radius);
    padding: 0.75rem 1rem;
    box-shadow: 1px 2px 8px transparent;
    outline: none;
    font-family: var(--font-family);
    font-weight: 700;

    &:focus {
      box-shadow: 1px 2px 8px var(--border-color);
    }
  }

  &__cta {
    border: 0;
    border-radius: var(--border-radius);
    background-color: transparent;
    padding: 0.75rem 2.25rem;
    margin: 1rem auto 0;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-decoration: none;

    span {
      color: var(--color);
      position: relative;
      z-index: 3;
      padding-right: 1rem;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-right: 3px solid var(--color);
        border-bottom: 3px solid var(--color);
        transform: rotate(-45deg) translate(10px, -5px);
        height: 11px;
        width: 11px;
        transform-origin: 100% 100%;
        transition: var(--transition);
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      background-color: var(--color-action);
      transition: var(--transition);
      z-index: 1;
    }
    &::after {
      width: 0%;
      z-index: 2;
      background-color: var(--border-color);
    }

    &:hover {
      &::after {
        width: 100%;
      }

      span::after {
        transform: rotate(-45deg) translate(12px, -3px);
      }
    }

    &.is--filled {
      opacity: 0.5;
      cursor: wait;

      &::after {
        width: 0% !important;
      }

      span {
        padding-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &__gdpr {
    padding: 1rem 0;
  }

  &__checkbox {
    display: block;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0px;
      height: 0px;
      position: absolute;

      &:checked ~ p::before {
        background-color: var(--color-action);
        border-color: var(--color-action);
      }
    }
    p {
      position: relative;
      padding: 0 0 0 1.5rem;
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.3;
      display: inline-block;

      a {
        color: var(--color-action);
        font-weight: 600;
        text-decoration: none;
        position: relative;
        display: inline-block;

        &::after {
          content: '';
          bottom: 0;
          left: 0;
          height: 2px;
          position: absolute;
          background-color: var(--color-action);
          width: 0%;
          transition: var(--transition);
        }

        &:hover::after {
          width: 100%;
        }
      }

      sup {
        color: var(--color-action);
        font-weight: 600;
        position: relative;
        top: 2px;
        margin: 0;
        padding: 0;
        line-height: 0;
      }

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid var(--border-color);
        border-radius: var(--border-radius-sm);
        transition: var(--transition);
      }
      &::after {
        content: '';
        display: block;
        opacity: 1;
        position: absolute;
        top: 1px;
        left: 5px;
        width: 6px;
        height: 10px;
        border: 2px solid var(--color);
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg);
        transition: var(--transition);
      }
    }

    & + & {
      margin-top: 0.5rem;
    }
  }
}
</style>
